<template>
<div>
<b-container fluid>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-primary card-outline">
                <div class="card-header">
                    <div class="card-title">
                        <h4><i class="fa fa-briefcase"></i>Angebote</h4>
                        <p>Liste aller Angebote</p>
                    </div>

                    <div class="clearfix card-tools">
                        <div class="row align-items-center">                            
                            <div class="text-right col-md-12">

                                <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                                    <ul class="navbar-nav">  

                                        <li class="nav-item mr-1">
                                            <div class="input-group input-group-sm mt-0">
                                                <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="getResults" :value="search" @input="$store.commit('offers/changeSearch', $event.target.value)" />
                                                <button type="submit" class="btn btn-default btn-sm" @click.prevent="getResults">
                                                    <i class="fas fa-search"></i>
                                                </button>
                                                <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                                                    <i class="fas fa-times"></i>
                                                </button>
                                            </div>             
                                        </li>

                                        <li class="nav-item">
                                            <router-link class="btn btn-primary btn-sm mr-1" :to="{name: 'offers.create'}" v-if="$auth.check('offers.create')"><i class="fas fa-plus-circle"></i> Neues Angebot</router-link>             
                                        </li>

                                        <li class="nav-item">
                                            <button type="button" class="mr-1 btn btn-sm btn-success" @click="getResults" ><i class="fas fa-sync"></i></button>       
                                        </li>

                                        <li class="nav-item">
                                            <button type="button" class="btn btn-sm btn-secondary" data-widget="control-sidebar"><i class="fas fa-filter"></i></button>
                                        </li>
                                        
                                    </ul>
                
                                </nav>

                                <!-- <button type="button" class="mr-1 btn btn-sm btn-secondary" @click="showSupplierOrderModal" :disabled="selectedOrders.length <= 0" >Lieferantenbestellung</button>

                                <div class="mr-1 btn-group" role="group">
                                    <button type="button" class="btn btn-sm btn-warning dropdown-toggle" :disabled="selectedOrders.length <= 0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-boxes"></i> Stapelverarbeitung</button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" href="#" @click="setStatus('order', 2)">Status: In Bearbeitung</a>
                                        <a class="dropdown-item" href="#" @click="printPicklist">Drucken: Pickliste</a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" href="#" @click="setStatusAndPrint('order', 2)">Status & Drucken</a>
                                    </div>
                                </div>


                                <router-link class="btn btn-primary btn-sm" :to="{name: 'orders.create'}" v-if="$auth.check('orders.create')"><i class="fas fa-plus-circle"></i> Neue Bestellung</router-link> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <b-row>
                        <b-col>
                            <div class="table-responsive">
                            <table class="table table-hover table-sm">
                                <thead>                                    
                                    <th>
                                        <a href="#" @click.prevent="changeSort('offernumber')">Angebotsnummer</a>
                                        <span v-if="this.params.sort_field == 'offernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'offernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th>Gültig bis</th>
                                    <th>
                                        <a href="#" @click.prevent="changeSort('invoice_amount')">Betrag</a>
                                        <span v-if="this.params.sort_field == 'invoice_amount' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'invoice_amount' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th>Firma</th>
                                    <th>Kunde</th>
                                    <th>E-Mail</th>
                                    <th>Status</th>
                                    <th>Aktion</th>
                                    
                                </thead>
                                <tbody>
                                    <tr v-for="(offer, index) in offers.data" :key="offer.id">
                                        <td>
                                            <span class="badge badge-pill badge-dark">{{ offer.offernumber }}</span>
                                        </td>
                                        <td>
                                            {{ offer.expires_at | formatDate }}
                                        </td>
                                        <td>
                                            {{ offer.invoice_amount | toCurrency }}
                                        </td>
                                        <td>{{ offer.company.name }}</td>
                                        <!-- <td>{{ order.customer.last_name}}, {{ order.customer.first_name}}</td> -->
                                        <td>
                                            <span v-if="offer.billing.company != null"><strong>{{ offer.billing.company }}</strong><br></span>
                                            <span>{{ offer.billing.last_name}}, {{ offer.billing.first_name }}</span>
                                        </td>
                                        <td>{{ offer.customer.email}}</td>
                                        <td>
                                            <!-- <span class="badge" :class="setStatusBadge(offer.status.description)">{{ offer.status.description }}</span> -->

                                            <div v-if="selectedRow[index] && selectedCell === 'offer_status'" class="input-group">
                                                <select class="form-control form-control-sm" v-model="offers.data[index].status_id" @change="changeStatus(index)" v-if="$auth.check('offers.edit')">
                                                    <option v-for="state in offerStates" :key="state.id" :value="state.id">{{ state.description }}</option> 
                                                </select>
                                                <div class="input-group-append">
                                                    <!-- <button class="btn btn-sm btn-primary" type="button" @click="changeStatus('order', index)"><i class="fas fa-save"></i></button> -->
                                                    <button class="btn btn-sm btn-secondary" type="button" @click="handleEditCell(index, 'offer_status')"><i class="fas fa-times"></i></button>
                                                </div>
                                            </div>
                                            <span @dblclick="handleEditCell(index, 'offer_status')" v-else class="badge" :class="setStatusBadge(offer.status.description)">{{ offer.status.description }}</span>
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-primary btn-xs mr-1" @click="downloadOffer(offer)"><i class="fas fa-fw fa-download"></i></button>
                                            <b-button class="mr-1" size="xs" @click="sendOffer(offer)" variant="secondary" v-if="$auth.check('offers.create')"><i class="fas fa-fw fa-envelope"></i></b-button>
                                            <b-button class="mr-1 bg-indigo" size="xs" @click="createOrder(offer)" variant="secondary" v-if="$auth.check('orders.create')"><i class="fas fa-fw fa-clipboard"></i></b-button>
                                            <!-- <router-link class="mr-1 btn btn-success btn-sm" :to="{name: 'products.details', params: {id: props.row.product.id}}" v-if="$auth.check('products.show') && props.row.product != null"><i class="fas fa-box"></i></router-link> -->
                                            <router-link class="mr-1 btn btn-success btn-xs" :to="{name: 'customers.details', params: {id: offer.customer_id}}" v-if="$auth.check('customers.show')"><i class="fas fa-fw fa-user"></i></router-link>
                                            <!-- <router-link class="mr-1 btn btn-info btn-xs" :to="{name: 'abos.show', params: {id: order.id}}" v-if="$auth.check('abos.show') && order.type != 'backend'"><i class="fas fa-eye"></i></router-link> -->
                                            <router-link class="mr-1 btn btn-warning btn-xs" :to="{name: 'offers.details', params: {id: offer.id}}" v-if="$auth.check('offers.edit')"><i class="fas fa-fw fa-edit"></i></router-link>
                                            <b-button size="xs" @click="deleteOffer(offer.id)" variant="danger" v-if="$auth.check('offers.destroy')"><i class="fas fa-fw fa-trash"></i></b-button>
                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                            
                        </b-col>
                    </b-row>
                </div>
                <div class="clearfix align-middle card-footer">
                    <pagination v-if="notEmptyObject(offers)" class="float-left" :data="offers" @pagination-change-page="getResults" :limit="3"></pagination>
                    
                    <select class="float-right form-control-sm" v-model="params.per_page">
                        <option value="25">25 Artikel</option>
                        <option value="50">50 Artikel</option>
                        <option value="75">75 Artikel</option>
                        <option value="100">100 Artikel</option>
                        <option value="125">125 Artikel</option>
                        <option value="150">150 Artikel</option>
                    </select>
                    <p v-if="notEmptyObject(offers)" class="float-right mr-2">Anzeige Eintrag {{ offers.meta.from }} - {{ offers.meta.to }} von {{ offers.meta.total }}</p>
                </div>
            </div>
        </div>
    </div>

    <b-modal :no-enforce-focus="true" id="mailDocumentModal" scrollable title="E-Mail an den Kunden senden" ok-variant="primary" ok-title="E-Mail senden" cancel-title="Abbrechen" size="xl" @ok="sendNotification">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="">An:</label>
                        <select class="form-control form-control-sm" multiple v-model="selected_emails">
                        <option v-for="email in emails" :key="email" :value="email">{{ email }}</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label for="">Betreff:</label>
                        <input type="text" class="form-control form-control-sm" v-model="subject" />
                    </div>

                    <div class="form-group">
                         
                        <editor
                                v-bind:api-key="this.$tinyApiKey"
                                :init="{
                                    height: 400,
                                    menubar: true,
                                    plugins: [
                                    'code advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | code | help'
                                }"
                                v-model="message"
                              
                                />
                    </div>
                </div>
            </div>

            <template #modal-footer="{ ok, cancel}">
            <b-button size="sm" variant="light" @click="cancel()">
                Abbrechen
            </b-button>
            <b-button size="sm" variant="primary" @click="ok()" :disabled="selected_emails.length <= 0">
                Senden
            </b-button>
            </template>
        </b-modal>
</b-container>
<aside class="control-sidebar control-sidebar-light">
        <div class="p-3">
            <h3>Filteroptionen</h3>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Shop:</label>
                        <select v-model="params.company_id" class="form-control form-control-sm">
                            <option :value="''">-- Filter Firma --</option>
                            <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Zahlungsart:</label>
                        <select v-model="params.payment_id" class="form-control form-control-sm">
                            <option :value="''">-- Filter Zahlungsart --</option>
                            <option v-for="payment in payments" :key="payment.id" :value="payment.id">{{ payment.description }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Versandart:</label>
                        <select v-model="params.dispatch_id" class="form-control form-control-sm">
                            <option :value="''">-- Filter Versandart --</option>
                            <option v-for="dispatch in dispatches" :key="dispatch.id" :value="dispatch.id">{{ dispatch.name }}</option>
                        </select>
                    </div>
                </div>
            </div>


            <div class="row mt-2">
                <div class="col-md-12 text-center">
                    <div class="form-group">
                        <button type="button" class="btn btn-sm btn-secondary" @click="resetFilter">Filter zurücksetzen</button>
                    </div>
                </div>
            </div>

        </div>  
    </aside>
</div>
</template>

<script>
import {mapState} from 'vuex';
import Editor from "@tinymce/tinymce-vue";
// import ClassicEditor from '../../../assets/packages/ckeditor5/src/ckeditor';
import "tinymce/tinymce";
// import "./../../../assets/packages/tinymce-bootstrap";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/bootstrap";
import "tinymce/plugins/code";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/lists";
import "tinymce/plugins/charmap";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/help";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/table";

export default {

    title: '',
    name: 'OffersIndex',

    data() {
        return {
            offers: [],
            selectedOffer: {},
            companies: [],
            dispatches: [],
            payments: [],
            selectedRow: {},
            selectedCell: null,
            offerStates: {},
            emails: [],
            message: "",
            subject: "",
            selected_emails: [],
            editor: Editor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
        }
    },

    watch: {
        params: {
            handler () {
                this.getResults();
            },
            deep: true
        },
    },

    computed:{
        ...mapState('offers', ['params', 'search', 'page'])
    },

    methods:{ 
        downloadOffer(offer)
        {
            this.axios
                .get("/offers/" + offer.id + "/download", {responseType: 'arraybuffer'})
                .then((response) => {
                    //this.orders = response.data.data;
                    this.downloadFile(response, offer)
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        downloadFile(response, offer){
            var newBlob = new Blob([response.data], {type: 'application/pdf'})

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob)
            return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            link.download = 'Angebot-' + offer.offernumber + '.pdf'
            link.click()
            setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
            }, 100)
        },

        createOrder(offer){
            this.$swal({
                    title: "Bestellung generieren?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.axios
                        .post('offers/convert', {
                            'offer_id': offer.id,
                        })
                        .then((response) => {
                            this.$swal({
                                icon: "success",
                                title: "Bestellung erstellt",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                            });
                            this.$router.push({name: 'orders.details', params: { id: response.data.data.id }});
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                    }
                });
        },

        sendNotification(bvModalEvt){
            bvModalEvt.preventDefault();
            this.axios
                .post("/mail/send/offer", {
                    'emails': this.selected_emails,
                    'subject': this.subject,
                    'content': this.message,
                    'company_id': this.selectedOffer.company.id,
                    'offer_id': this.selectedOffer.id,
                })
                .then(() => {
                    this.$bvModal.hide('mailDocumentModal');
                    this.selected_emails = [];
                    this.message = '';
                    this.subject = '';
                    this.selected_emails = [];
                    this.emails = [];
                    this.selectedOffer = {};
                    this.$swal({
                        icon: "success",
                        title: "E-Mail gesendet",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        });
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        sendOffer(offer){
            this.axios
            .get('/offers/' + offer.id + '/template/mail')
            .then((response) => {
                this.emails = [];
                this.emails.push(offer.customer.email);
                if(offer.customer.invoice_email != null)
                {
                    this.emails.push(offer.customer.invoice_email);
                }
                this.message = '';
                this.message = response.data.message;
                this.subject = 'Dein Angebot vom ' + offer.company.name;
                this.selectedOffer = offer;
                this.$bvModal.show('mailDocumentModal')
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        },

        changeStatus(offer)
        {
            var offers = [this.offers.data[offer].id];
            // var status = this.offerStates.find(state => state.id == this.offers.data[offer].status_id);
            this.axios
                .post("/offers/changeStatus", {
                    offers: offers,
                    status_id: this.offers.data[offer].status_id,
                })
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Status erfolgreich geändert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        });
                    this.handleEditCell(offer, 'offer_status')
                    this.getResults();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        getOfferStates(){
            this.axios
                .get("/states/offer")
                .then((response) => {
                    this.offerStates = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }, 

        handleEditCell(index, name) {
            this.selectedCell = name;
            this.selectedRow = {
            [index]: !this.selectedRow[index]
            }
        },

        setStatusBadge(state){

            if(state == 'Offen')
            {
                return 'badge-primary'
            }

            if(state == 'Gesendet')
            {
                return 'badge-warning'
            }

            if(state == 'Angenommen')
            {
                return 'badge-success'
            }

            if(state == 'Abgelehnt')
            {
                return 'badge-danger'
            }

            if(state == 'Bestellung')
            {
                return 'badge-dark'
            }

            return 'badge-primary';
        },

        resetSearch(){
            this.$store.commit('offers/changeSearch', '');
            this.getResults();
        },

        resetFilter(){
            this.params.payment_id = '';
            this.params.dispatch_id = '';
            this.params.company_id = '';
            this.params.per_page = 25;
        },

        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        deleteOffer(id){
            this.$swal({
                title: "Möchtest du das Angebot wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.axios
                        .delete("/offers/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Angebot erfolgreich gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.getResults();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },


        getPayments(){
            this.$Progress.start();
            this.axios
                .get("/payments")
                .then((response) => {
                    this.payments = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getDispatches(){
            this.$Progress.start();
            this.axios
                .get("/dispatches")
                .then((response) => {
                    this.dispatches = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getCompanies(){
            this.axios
                .get("/companies")
                .then((response) => {
                    this.companies = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        getResults(page) {
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/offers", {
                    params: {
                        page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.offers = response.data;
                    this.$store.commit('offers/setPage', page);
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }
    },

    created() {
        this.getResults();
        this.getPayments();
        this.getDispatches();
        this.getCompanies();
        this.getOfferStates();
    }
}
</script>

<style>
.ck-editor__editable {
    min-height: 500px;
    max-height: 500px;
}
</style>